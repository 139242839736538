import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../context/theme-context";
import { useAnimate, stagger, motion } from "framer-motion";
import { Link } from "gatsby";
import { useTranslations } from "../../i18n/utils";
import LanguageSwitcher from "./LanguageSwitcher";
import menu from "../../data/menu.json";
import { isDesktop } from "react-device-detect";

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate();
  useEffect(() => {
    const menuAnimations = isOpen
      ? [
          [
            "#backdrop",
            {
              opacity: 0.3,
            },
            {
              duration: 0.3,
            },
          ],
          [
            "#menu-background",
            { transform: "translateY(0%)", opacity: 1 },
            {
              at: "<",
              duration: 1.2,
              ease: [0.5, 0, 0.2, 0.99],
            },
          ],
          [
            "li",
            { opacity: 1 },
            {
              duration: 0.3,
              delay: stagger(0.03, { from: "first" }),
              at: 0.6,
            },
          ],
        ]
      : [
          ["li", { opacity: 0 }, { delay: stagger(0.03, { from: "last" }) }],
          [
            "#menu-background",
            { transform: "translateY(-100%)", opacity: 0 },
            {
              duration: 0.6,
              ease: [0.5, 0, 0.2, 0.99],
              at: "<",
            },
          ],
          [
            "#backdrop",
            {
              opacity: 0,
            },
            {
              at: "<",
            },
          ],
        ];

    animate([...menuAnimations]);
  }, [isOpen, animate]);

  return scope;
}

const SmoothMenu = ({ location, width, setIsOpen, isOpen }) => {
  const scope = useMenuAnimation(isOpen);

  useEffect(() => {
    setIsOpen(false);
  }, [location, setIsOpen]);

  const isHomepage = location.pathname === "/" || location.pathname === "/en/";
  return (
    <>
      <div
        ref={scope}
        className={`${!isOpen ? "pointer-events-none" : "pointer-events-auto"}`}
      >
        {!isHomepage && (
          <motion.div
            id="backdrop"
            className="absolute top-0 left-0 w-full bg-black h-screen z-10 opacity-0"
            onClick={() => setIsOpen(false)}
          />
        )}
        <div
          className={`relative py-2 md:py-4 pt-[calc(30px+0.525em)] md:pt-[calc(30px+0.63em)]`}
        >
          {!isHomepage && (
            <div
              id="menu-background"
              className="absolute top-0 left-0 w-full h-[100%] bg-white z-10 opacity-0"
            />
          )}

          <Menu location={location} setIsOpen={setIsOpen} isOpen={isOpen} />
        </div>
      </div>
    </>
  );
};

export default SmoothMenu;

const Menu = ({ location, setIsOpen, isOpen, width }) => {
  const { currentLocale } = useContext(ThemeContext);
  const t = useTranslations(currentLocale);

  const handleHoverEnd = () => {
    if (isDesktop) {
      setIsOpen(false);
    }
  };
  return (
    <motion.ul
      onHoverStart={() => setIsOpen(true)}
      onHoverEnd={handleHoverEnd}
      className={`leading-tight ${
        !isOpen ? "pointer-events-none" : "pointer-events-auto"
      } block relative z-10`}
    >
      <span className="italic text-transparent text-xs absolute top-0 left-0">
        t
      </span>
      {menu.pages.slice(1).map((item, index) => {
        return (
          <li key={`nav-item-${index}`} className="opacity-0">
            <Link
              to={currentLocale === "en" ? item.en : item.it}
              className="hover:italic px-2 md:px-4"
              activeClassName="italic"
            >
              {t(item.slug)}
            </Link>

            {item.children !== null && (
              <ul className="pl-8">
                {item.children.map((child, index) => {
                  return (
                    <li key={`parent-page-${index}`}>
                      <Link
                        to={currentLocale === "en" ? child.en : child.it}
                        className="hover:italic"
                        activeClassName="italic"
                      >
                        {t(child.slug)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
      <li className="pt-4 px-2 md:px-4 opacity-0">
        <LanguageSwitcher location={location} />
      </li>
    </motion.ul>
  );
};
