import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { ThemeContext } from "../context/theme-context";
import { getLangFromUrl } from "../../i18n/utils";

const LanguageSwitcher = ({ location }) => {
  const { currentLocale, setCurrentLocale, translatedDest } =
    useContext(ThemeContext);

  const handleLanguageSwitch = (currentLocale) => {
    if (currentLocale === "en") {
      setCurrentLocale("it");
    } else {
      setCurrentLocale("en");
    }
    navigate(translatedDest);
  };

  useEffect(() => {
    setCurrentLocale(getLangFromUrl(location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localeToDisplay = currentLocale === "en" ? "It" : "En";
  return (
    <button onClick={() => handleLanguageSwitch(currentLocale)}>
      {localeToDisplay}
    </button>
  );
};

export default LanguageSwitcher;
