exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-contacts-jsx": () => import("./../../../src/templates/page-contacts.jsx" /* webpackChunkName: "component---src-templates-page-contacts-jsx" */),
  "component---src-templates-page-homepage-jsx": () => import("./../../../src/templates/page-homepage.jsx" /* webpackChunkName: "component---src-templates-page-homepage-jsx" */),
  "component---src-templates-page-press-jsx": () => import("./../../../src/templates/page-press.jsx" /* webpackChunkName: "component---src-templates-page-press-jsx" */),
  "component---src-templates-page-projects-jsx": () => import("./../../../src/templates/page-projects.jsx" /* webpackChunkName: "component---src-templates-page-projects-jsx" */),
  "component---src-templates-page-single-press-jsx": () => import("./../../../src/templates/page-single-press.jsx" /* webpackChunkName: "component---src-templates-page-single-press-jsx" */),
  "component---src-templates-page-single-project-jsx": () => import("./../../../src/templates/page-single-project.jsx" /* webpackChunkName: "component---src-templates-page-single-project-jsx" */),
  "component---src-templates-page-studio-jsx": () => import("./../../../src/templates/page-studio.jsx" /* webpackChunkName: "component---src-templates-page-studio-jsx" */)
}

