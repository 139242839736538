export const languages = {
  en: "EN",
  it: "IT",
};

export function isValidLanguageCode(
  languageCode: string
): languageCode is keyof typeof languages {
  return languageCode in languages;
}

export const defaultLang = "it";
export const showDefaultLang = false;
export const ui = {
  it: {
    onview: "In Mostra",
    currentLocale: "Lingua corrente",
    residential: "Residenziale",
    retail: "Commerciale",
    public: "Pubblico",
    projects: "Progetti",
    studio: "Studio",
    contacts: "Contatti",
    press: "Press",
    photo: "Foto",
    collaborators: "Collaboratori",
    moreCollaborators: "Altri Collaboratori",
    credits: "Crediti",
  },
  en: {
    onview: "On View",
    currentLocale: "Current Language",
    residential: "Residential",
    retail: "Retail",
    public: "Public",
    projects: "Projects",
    studio: "Studio",
    contacts: "Contacts",
    press: "Press",
    photo: "Photo",
    collaborators: "Collaborators",
    moreCollaborators: "More Collaborators",
    credits: "Credits",
  },
} as const;
