import React from "react";
import SmoothMenu from "./SmoothMenu";
import SmoothMenuHomepage from "./SmoothMenuHomepage";

const Header = ({ location, width, setIsOpen, isOpen }) => {
  const isHomepage = location.pathname === "/" || location.pathname === "/en/";

  return (
    <header
      className={`whitespace-nowrap fixed top-0 z-10 left-0 w-full text-xl md:text-default leading-tight max-h-[60px] ${
        isHomepage ? "mix-blend-difference text-white" : ""
      }`}
    >
      {location.pathname !== "/en/" && location.pathname !== "/" ? (
        <SmoothMenu
          location={location}
          width={width}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <SmoothMenuHomepage
          location={location}
          width={width}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </header>
  );
};
export default Header;
