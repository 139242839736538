import React, { useContext, useState } from "react";
import { ThemeContext } from "../context/theme-context";
import { AnimatePresence, motion } from "framer-motion";
import Header from "./Header";
import { Link } from "gatsby";

const LayoutWrapper = ({ children, location }) => {
  const { layout, currentLocale, width } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div ref={layout} className="antialiased font-body bg-white relative z-10">
      <motion.button
        className="fixed top-4 left-2 md:left-4 z-20 text-white mix-blend-difference text-xl md:text-default "
        onHoverStart={() => setIsOpen(true)}
        onTapStart={() => setIsOpen(!isOpen)}
      >
        <>
          {width > 768 ? (
            <Link to={currentLocale === "en" ? "/en/" : "/"}>Studio LGB</Link>
          ) : (
            <span>Menu</span>
          )}
        </>
      </motion.button>
      {width < 768 &&
        location.pathname !== "/en/" &&
        location.pathname !== "/" && (
          <span className="fixed top-4 right-2 z-20 text-white mix-blend-difference text-xl md:text-default">
            <Link to={currentLocale === "en" ? "/en/" : "/"}>Studio LGB</Link>
          </span>
        )}
      <Header
        location={location}
        currentLocale={currentLocale}
        width={width}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <AnimatePresence mode="sync">{children}</AnimatePresence>
    </div>
  );
};

export default LayoutWrapper;
