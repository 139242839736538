import React from "react";
import { ThemeProvider } from "./src/components/context/theme-context";
import LayoutWrapper from "./src/components/layout/LayoutWrapper";
import "./src/styles/global.css";

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    <LayoutWrapper {...props}>{element}</LayoutWrapper>
  </ThemeProvider>
);
