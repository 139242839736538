import React, { useState, createContext, useRef, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";

const ThemeContext = createContext({
  layout: { current: null },
  width: 0,
  height: 0,
  isFirstTime: false,
  setIsFirstTime: () => {},
  mobileMenuState: false,
  setMobileMenuState: () => {},
  setIsOverHalf: () => {},
  isOverHalf: false,
  currentLocale: "it",
  setCurrentLocale: () => {},
  translatedDest: "/",
  setTranslatedDest: () => {},
});

const ThemeProvider = ({ children }) => {
  const layout = useRef(null);
  const { width, height } = useWindowSize();

  const [isFirstTime, setIsFirstTime] = useState(false);
  useEffect(() => {
    setIsFirstTime(sessionStorage.getItem("isFirstTime") === null);
  }, []);
  useEffect(() => {
    if (isFirstTime) {
      sessionStorage.setItem("isFirstTime", "false");
    }
  }, [isFirstTime]);

  const [mobileMenuState, setMobileMenuState] = useState(false);
  const [isOverHalf, setIsOverHalf] = useState(false);

  const [currentLocale, setCurrentLocale] = useState("it");
  const [translatedDest, setTranslatedDest] = useState("/");
  return (
    <ThemeContext.Provider
      value={{
        layout,
        width,
        height,
        isFirstTime,
        setIsFirstTime,
        mobileMenuState,
        setMobileMenuState,
        setIsOverHalf,
        isOverHalf,
        currentLocale,
        setCurrentLocale,
        translatedDest,
        setTranslatedDest,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
